import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

import { Accordion, Button, Drawer, Link } from "@global";
import { SanityNavigation } from "@graphql-types";
import { Container } from "@util/standard";
import { assets, colorsRGB, fontWeights } from "@util/constants";
import { isSanityImageMenu, isSanityLink } from "@util/types";
import { useStore } from "@state/store";
import SearchBar from "./searchBar";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  data: SanityNavigation;
}

const Icon = styled.img`
  width: 24px;
  height: 33px;
  margin: 6px 10px auto 20px;
`;

const StyledLink = styled(Link)`
  font-size: 20px;
  padding: 30px 0;
  font-weight: ${fontWeights.bold};
  border-bottom: 1px solid ${colorsRGB.navy(0.2)};
`;

const LogoImage = styled.img`
  width: 96px;

  margin: auto;
`;

function MobileMenu({ data }: Props) {
  if (data == null) return null;
  const { logo, links } = data;

  const [menuOpen, setMenuOpen] = useState(false);

  const { openDrawer } = useStore();
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  const isMobileDevice = isTabletWidth || isMobileWidth;

  useEffect(() => {
    () => {
      return setMenuOpen(false);
    };
  }, []);
  return (
    <>
      <Container>
        <Icon src={assets.hamburger} onClick={() => setMenuOpen(true)} />
      </Container>
      <Drawer
        placement="right"
        width={isMobileDevice ? "100%" : "60%"}
        height="100%"
        visible={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <Container width="100%" flexDirection="column" margin="22px 0 0 0" overflow="scroll">
          <Container width="80%" justifyContent="space-between">
            {logo && logo.asset && (
              <GatsbyLink to="/" style={{ margin: "auto 0 auto 4%" }}>
                <LogoImage alt={logo.altText ?? "Header logo"} src={logo.asset.url as string} />
              </GatsbyLink>
            )}
            <Button
              onClick={() => {
                setMenuOpen(false);
                openDrawer();
              }}
              theme="base"
              linkText="Enquiry"
              padding="12px 29px"
            />
          </Container>

          {links && (
            <Container width="80%" margin="30px auto" flexDirection="column">
              {links.map(data => {
                if (data == null) return null;

                if (isSanityImageMenu(data)) {
                  if (data.menuLinks == null) {
                    return null;
                  }
                  return (
                    <Accordion text={data.menuTitle} key={data._key}>
                      {data.menuLinks.map(link => {
                        if (link == null || link.link == null) return null;
                        return (
                          <div onClick={() => setMenuOpen(false)}>
                            <Link
                              {...link.link}
                              padding="20px 0 0 18px"
                              color="navy"
                              key={link._key}
                            />
                          </div>
                        );
                      })}
                    </Accordion>
                  );
                }
                if (isSanityLink(data)) {
                  return (
                    <div onClick={() => setMenuOpen(false)} key={data._key}>
                      <StyledLink {...data} color="navy" />
                    </div>
                  );
                }
                return null;
              })}
            </Container>
          )}
          <Container width="85%" margin="20px auto">
            <SearchBar onSearch={() => setMenuOpen(false)} />
          </Container>
        </Container>
      </Drawer>
    </>
  );
}

export default MobileMenu;
