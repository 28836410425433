import React from "react";
import styled from "styled-components";

import { BlocksContent, Image } from "@global";
import { SanityColumnContent } from "@graphql-types";
import { Container } from "@util/standard";
import { StandardLayout } from "./global.styles";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityColumnContent;
}

const TextWrapper = styled(Container)`
  margin: auto 0;
  h1,
  h2,
  h3,
  h4 {
    margin-top: 0px;
  }
`;

const ImageWrapper = styled(Container)`
  width: 45%;
  height: 400px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin-bottom: 40px;
    height: 400px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 250px;
  }
`;

function ColumnContent({ data }: Props) {
  if (data == null) return null;
  return (
    <StandardLayout margin="150px auto" tabletMargin="70px auto">
      {data.columnImage && (
        <ImageWrapper width="45%" tabletWidth="100%" margin={!data.reverse ? "0 10% 0 0" : "0"}>
          <Image data={data.columnImage} objectFit="cover" />
        </ImageWrapper>
      )}
      {data.columnText && (
        <TextWrapper
          width="38%"
          tabletWidth="100%"
          margin={data.reverse ? "0 10% 0 0" : "0"}
          tabletMargin="40px 0 0 0"
        >
          <BlocksContent data={data.columnText} />
        </TextWrapper>
      )}
    </StandardLayout>
  );
}

export default ColumnContent;
