import styled from "styled-components";
import { CSSProperties } from "react";

import { colors, colorsRGB, fontWeights, PRIMARY_COLOR } from "./constants";
import { Color, FontWeights } from "./types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

export const Overlay = styled.div<{ zIndex?: number }>`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: ${props => props.zIndex ?? 3};
  left: 0;
  top: 0;
  background-color: ${colorsRGB.black(0.5)};
  overflow: hidden;
  transition: 0.5s;
`;

export const P = styled.p<{
  color?: Color;
  fontSize?: number;
  width?: string;
  lineHeight?: number;
  underlined?: boolean;
  fontWeight?: FontWeights;
  opacity?: number;
  display?: CSSProperties["display"];
  whiteSpace?: CSSProperties["whiteSpace"];
  cursor?: CSSProperties["cursor"];
  padding?: string;
  textAlign?: CSSProperties["textAlign"];
  letterSpacing?: CSSProperties["letterSpacing"];
  margin?: string;
  tabletMargin?: string;
  userSelect?: string;
}>`
  background-color: transparent;
  text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
  font-weight: ${props => (props.fontWeight ? fontWeights[props.fontWeight] : fontWeights.normal)};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ userSelect }) => userSelect && `user-select: ${userSelect};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ display }) => display && `display: ${display};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  @media only screen and (max-width:${TABLET_BREAKPOINT}px) {
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`};
  }
`;

export const A = styled.a<{
  color?: Color;
  underlined?: boolean;
  bold?: boolean;
  hoverColor?: Color;
  opacity?: number;
  margin?: string;
}>`
  display: block;
  color: ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ underlined }) => (underlined ? "text-decoration: underline;" : "text-decoration: none;")}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
    color: ${colors[hoverColor]};
  }`}
`;

export const VerticalSeparator = styled.div<{
  color?: Color;
  width?: string;
  height?: string;
  opacity?: number;
  shouldShowMobile?: boolean;
}>`
  border-left: 1.5px solid ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  height: ${props => (props.height ? props.height : `auto`)};
  width: ${props => (props.width ? props.width : `20px`)};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const Separator = styled.div<{
  color?: Color;
  height?: string;
  opacity?: number;
  thickness?: number;
  shouldHideDesktop?: boolean;
  marginBottom?: number;
  width?: string;
}>`
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px;` : `margin-bottom: 10px`};
  border-bottom: ${props => props.thickness ?? 3}px solid
    ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ width }) => width && `width: ${width ?? "100%"};`}
  height: ${props => props.height ?? `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  ${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
  }
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
  }
`;

export const Container = styled.div<{
  backgroundColor?: Color;
  backgroundRGBColor?: string;
  flexDirection?: CSSProperties["flexDirection"];
  flex?: CSSProperties["flex"];
  flexGrow?: CSSProperties["flexGrow"];
  flexWrap?: CSSProperties["flexWrap"];
  flexFlow?: CSSProperties["flexFlow"];
  alignItems?: CSSProperties["alignItems"];
  alignSelf?: CSSProperties["alignSelf"];
  mobileAlignSelf?: CSSProperties["alignSelf"];
  borderRadius?: number;
  overflow?: CSSProperties["overflow"];
  paddingVertical?: number;
  paddingHorizontal?: number;
  img?: string;
  isMobileColumn?: boolean;
  mobilePadding?: string;
  tabletWidth?: string;
  tabletHeight?: string;
  tabletMargin?: string;
  tabletPadding?: string;
  mobileWidth?: string;
  mobileMargin?: string;
  justifyContent?: CSSProperties["justifyContent"];
  mobileJustifyContent?: CSSProperties["justifyContent"];
  tabletFlexDirection?: CSSProperties["flexDirection"];
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  mobileHeight?: string;
  opacity?: number;
  margin?: string;
  padding?: string;
  cursor?: CSSProperties["cursor"];
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  showOnTablet?: boolean;
  zIndex?: number;
  display?: CSSProperties["display"];
  backgroundSize?: CSSProperties["backgroundSize"];
  position?: CSSProperties["position"];
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  mobileOverFlowX?: CSSProperties["overflow"];
  tabletOverFlowX?: CSSProperties["overflow"];
  pointerEvents?: string;
}>`
  display: ${props => props.display ?? "flex"};
  flex-direction: ${props => props.flexDirection ?? "initial"};
  justify-content: ${props => props.justifyContent ?? "initial"};
  align-items: ${props => props.alignItems ?? "initial"};
  align-self: ${props => props.alignSelf ?? "initial"};
  overflow: ${props => props.overflow ?? "initial"};
  border-radius: ${props => props.borderRadius ?? 0}px;

  ${({ pointerEvents }) => pointerEvents && `pointer-event: ${pointerEvents};`};
  ${({ position }) => position && `position: ${position};`}
  ${({ backgroundSize }) => backgroundSize && `background-size: ${backgroundSize};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ flexFlow }) => flexFlow && `flex-flow: ${flexFlow};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ img }) => img && `background-image: url(${img});`}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) => backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ showOnTablet }) => showOnTablet && `display: none;`}
  ${({ top }) => top && `top: ${top};`};
  ${({ bottom }) => bottom && `bottom: ${bottom};`};
  ${({ left }) => left && `left: ${left};`};
  ${({ right }) => right && `right: ${right};`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isMobileColumn }) =>
      isMobileColumn &&
      `
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}

    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding};`};
    ${({ tabletPadding }) => tabletPadding && `padding: ${tabletPadding};`};
    ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
    ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
      ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
    ${({ tabletHeight }) => tabletHeight && `height: ${tabletHeight};`};
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`};
    ${({ tabletFlexDirection }) =>
      tabletFlexDirection && `flex-direction: ${tabletFlexDirection};`};
    ${({ tabletOverFlowX }) => tabletOverFlowX && `overflow-x: ${tabletOverFlowX};`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}

    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
    ${({ mobileAlignSelf }) => mobileAlignSelf && `align-self: ${mobileAlignSelf}`}
      ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
    ${({ mobileOverFlowX }) => mobileOverFlowX && `overflow-x: ${mobileOverFlowX};`};
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`};
    ${({ mobileHeight }) => mobileHeight && `height: ${mobileHeight};`}
  }
`;

export const GridContainer = styled.div<{
  gridTemplateColumns?: string;
  width?: string;
  mobileWidth?: string;
  margin?: string;
  tabletWidth?: string;
  tabletMargin?: string;
  repeat?: number;
  mobileRepeat?: number;
  tabletRepeat?: number;
  columnGap?: number;
  tabletColumnGap?: number;
  rowGap?: number;
  mobileColumnGap?: number;
  mobileRowGap?: number;
  rtl?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(${props => props.repeat ?? 4}, 1fr);
  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap}px;`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ rtl }) => rtl && `direction: rtl;`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(${props => props.tabletRepeat ?? 2}, 1fr);
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`};
    ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`}
    ${({ tabletColumnGap }) => tabletColumnGap && `grid-column-gap: ${tabletColumnGap}px;`}
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`}
    grid-template-columns: repeat(${props => props.mobileRepeat ?? 1}, 1fr);
    ${({ mobileColumnGap }) => mobileColumnGap && `grid-column-gap: ${mobileColumnGap}px;`}
    ${({ mobileRowGap }) => mobileRowGap && `grid-row-gap: ${mobileRowGap}px;`}
  }
`;

export const TextButton = styled(P)<{
  color?: Color;
  hoverColor?: Color;
  underlined?: boolean;
  lineHeight?: number;
  margin?: string;
}>`
  line-height: ${props => props.lineHeight ?? 18}px;
  text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
  color: ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  cursor: pointer;
  margin: ${props => props.margin ?? "0"};
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:focus {
    outline: 0;
  }
  &:hover {
  }
`;

export const GiveMeSomeSpace = styled.div<{ space?: number }>`
  padding: ${props => props.space ?? 10}px;
`;

export const FullHeightContainer = styled(Container)<{
  height?: string | undefined;
  width?: string | undefined;
}>`
  flex-direction: column;
  position: relative;
  width: ${props => props.width ?? "100%"};
  height: ${props => props.height ?? "100vh"};
  overflow: hidden;
`;

export const MobileContainer = styled(Container)<{ customVisibleBreakPoint?: number }>`
  display: none;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: flex;
  }
  ${({ customVisibleBreakPoint }) =>
    customVisibleBreakPoint &&
    `
    @media only screen and (max-width: ${customVisibleBreakPoint}px) {
      display: block;
  `}
`;

export const DesktopContainer = styled(Container)<{ customHideBreakPoint?: number }>`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
  ${({ customHideBreakPoint }) =>
    customHideBreakPoint &&
    `
    @media only screen and (max-width: ${customHideBreakPoint}px) {
      display: none;
  `}
`;

export const Span = styled.span<{
  fontSize?: number;
  display?: CSSProperties["display"];
  margin?: string;
}>`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ display }) => display && `display: ${display};`}
  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const AbsoluteContainer = styled(Container)<{
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  zIndex?: number;
  tabletPositioning?: { top?: string; bottom?: string; left?: string; right?: string };
  mobilePositioning?: { top?: string; bottom?: string; left?: string; right?: string };
}>`
  position: absolute;
  ${({ top }) => top && `top: ${top};`};
  ${({ left }) => left && `left: ${left};`};
  ${({ bottom }) => bottom && `bottom: ${bottom};`};
  ${({ right }) => right && `right: ${right};`};
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ tabletPositioning }) =>
      tabletPositioning &&
      `
    top: ${tabletPositioning.top};
    left: ${tabletPositioning.left};
    bottom: ${tabletPositioning.bottom};
    right: ${tabletPositioning.right};
    `};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobilePositioning }) =>
      mobilePositioning &&
      `
    top: ${mobilePositioning.top};
    left: ${mobilePositioning.left};
    bottom: ${mobilePositioning.bottom};
    right: ${mobilePositioning.right};
    `};
  }
`;
