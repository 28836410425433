import React, { useState } from "react";
import styled from "styled-components";

import { assets } from "@util/constants";
import { Container, P } from "@util/standard";

interface Props {
  label: string;
  onChange?: () => void;
}

const HiddenInput = styled.input`
  /* HIDES DEFAULT CHECKBOX OFF SCREEN WHILE KEEPING IT READABLE FOR E-READERS*/
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const CheckIcon = styled.img`
  margin-right: 10px;
`;

function CheckBox({ label, onChange }: Props) {
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    setChecked(!checked);
    if (onChange) {
      onChange();
    }
  };

  return (
    <Container margin="15px 0 0 0" onClick={handleClick} cursor="pointer">
      <HiddenInput aria-label={label} type="checkbox" checked={checked} readOnly />
      <CheckIcon src={checked ? assets.checked : assets.unChecked} alt="checkbox-icon" />
      <P margin="0">{label}</P>
    </Container>
  );
}

export default CheckBox;
