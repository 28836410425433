import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

import { ButtonTheme } from "@util/types";
import { buttonStyle, colors, fontWeights, MOBILE_BREAKPOINT } from "@util/constants";
import { Maybe, Scalars } from "@graphql-types";
import { Container } from "@util/standard";
import { Loading } from "@global";
import { formatInternalLink } from "@util/helper";
import { useStore } from "@state/store";

const StyledButton = styled.button<{
  theme: any;
  mobileMargin?: string;
  margin?: string;
  minHeight?: number;
  width?: string;
  padding?: string;
}>`
  text-align: center;
  cursor: pointer;
  user-select: none;
  position: relative;

  padding: ${props => (props.padding ? props.padding : `16px 25px`)};
  color: ${props => props.theme.text};
  border: ${props => props.theme && `1px solid ${props.theme.border}`};
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: ${fontWeights.bold};
  background-color: ${props => props.theme.bg};
  width: ${props => props.width ?? "fit-content"};
  ${({ margin }) => margin && `margin: ${margin};`}

  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
  
  &:focus {
    outline: 0;
  }

  ${({ theme }) => (theme && theme.underlined ? "text-decoration: underline;" : "")}

  overflow: hidden;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;

  &:hover {
    border: ${props => props.theme && `1px solid ${props.theme.hoverBorder}`};
    color: ${props => props.theme.hoverText ?? colors.transparent};
    background-color: ${props => props.theme.hoverBg ?? colors.transparent};
  }
  transition: all 0.2s;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
  }
`;

const Loader = () => (
  <Container justifyContent="center" alignItems="center">
    <Loading />
    <p style={{ margin: "0 0 0 5px" }}>Loading..</p>
  </Container>
);

interface Props {
  theme?: ButtonTheme;
  disabled?: boolean;
  linkText?: Maybe<string> | undefined;
  onClick?: (args?: any) => void;
  minHeight?: number;
  margin?: string;
  padding?: string;
  mobileMargin?: string;
  type?: "button" | "submit" | "reset";
  width?: string;
  externalLink?: Maybe<Scalars["String"]>;
  internalLink?: Maybe<any>;
  loading?: boolean;
  isProductEnquiryBtn?: Maybe<boolean> | undefined;
}

const Button = ({
  theme = "base",
  onClick,
  linkText,
  externalLink,
  internalLink,
  loading,
  isProductEnquiryBtn,
  ...rest
}: Props) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const { openDrawer } = useStore();

  if (isProductEnquiryBtn) {
    return (
      <StyledButton theme={buttonStyle[theme]} onClick={openDrawer} {...rest}>
        {loading ? <Loader /> : linkText}
      </StyledButton>
    );
  }

  if (internalLink) {
    const to = () => {
      if (internalLink?.slug) return formatInternalLink(internalLink) + internalLink.slug.current;

      return formatInternalLink(internalLink);
    };

    return (
      <GatsbyLink to={to()}>
        <StyledButton theme={buttonStyle[theme]} onClick={handleOnClick} {...rest}>
          {loading ? <Loader /> : linkText}
        </StyledButton>
      </GatsbyLink>
    );
  }

  if (externalLink) {
    return (
      <a href={externalLink} target="_blank">
        <StyledButton theme={buttonStyle[theme]} onClick={handleOnClick} {...rest}>
          {loading ? <Loader /> : linkText}
        </StyledButton>
      </a>
    );
  }

  return (
    <StyledButton theme={buttonStyle[theme]} onClick={handleOnClick} {...rest}>
      {loading ? <Loader /> : linkText}
    </StyledButton>
  );
};

export default Button;
