import React from "react";
import styled from "styled-components";

import { SanityListBlock } from "@graphql-types";
import {
  colorsRGB,
  TABLET_BREAKPOINT,
  fontWeights,
  TWENTY_INCH_SCREEN,
  IPAD_PRO_BREAKPOINT,
  SMALL_LAPTOP_BREAKPOINT,
} from "@util/constants";
import { Container, P } from "@util/standard";

interface Props {
  data: SanityListBlock;
}

const Wrapper = styled(Container)`
  border-bottom: 1px solid ${colorsRGB.navy(0.4)};
  width: 98%;
  margin: auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    width: 82%;
  }
`;

const HeaderWrapper = styled(Container)`
  width: 29%;
  border-right: 1px solid ${colorsRGB.navy(0.4)};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border: none;
    width: 100%;
  }
`;

const Header = styled.h4`
  width: 55%;
  font-weight: ${fontWeights.bold};
  margin: 120px auto 0 auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 25px;
    width: 50%;
    margin: 70px 0 20px 0;
  }
`;

const ListContainer = styled(Container)`
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 750px;
  margin: auto;
  @media only screen and (min-width: ${TWENTY_INCH_SCREEN}px) {
    max-height: 600px;
  }
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    max-height: 750px;
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    max-height: 750px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-wrap: no-wrap;
    max-height: 100%;
  }
`;

function ListBlock({ data }: Props) {
  if (data == null) return null;

  const { header, listItems } = data;
  return (
    <Wrapper>
      <HeaderWrapper>
        <Header>{header}</Header>
      </HeaderWrapper>
      <Container
        padding="120px 11%"
        mobilePadding="16px 0 70px 0"
        flexDirection="column"
        width="71%"
        tabletWidth="100%"
      >
        <ListContainer>
          {listItems?.map(item => {
            if (!item) return null;

            return (
              <Container
                flexDirection="column"
                width="48%"
                tabletWidth="100%"
                margin="0 0 50px 0"
                tabletMargin="0 0 35px 0"
                key={item._key}
              >
                <h4 style={{ margin: "0 0 10px 0" }}>{item.itemName}</h4>
                <P margin="0">{item.itemText}</P>
              </Container>
            );
          })}
        </ListContainer>
      </Container>
    </Wrapper>
  );
}

export default ListBlock;
