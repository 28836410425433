import { useAllProductQuery } from "@api";
import {
  Maybe,
  SanityAttribute,
  SanityAttributeBlock,
  SanityCollection,
  SanityProduct,
} from "@graphql-types";
import {
  isSanityAboutPage,
  isSanityCareersPage,
  isSanityCollection,
  isSanityFaqsPage,
  isSanityHomePage,
  isSanityPoliciesPage,
  isSanityProduct,
  isSanityPromotionalProducts,
  isSanityResourcesPage,
  isSanityTestimonialsPage,
} from "./types";

export const isBrowser = () => typeof window !== "undefined";

export function arraysEquality(array1: any[], array2: any[]) {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}

export function objectEquality(object1: any, object2: any) {
  return Object.keys(object1).every(key => object1[key] === object2[key]);
}

export function splitArrayIntoChunks(array: any[], split: number) {
  const chunks = array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / split);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return chunks;
}

export function splitArrayByCategory(
  products: SanityProduct[],
  collectionSlugs: (Maybe<string> | undefined)[],
) {
  const group = collectionSlugs.map(slug => {
    const productsInCollection = products.filter(
      product => product.categories && product.categories[0]?.collectionRef?.slug?.current === slug,
    );
    return productsInCollection;
  });

  return group;
}

const pages = require("./pagesPaths");

export function formatInternalLink(link: any) {
  if (isSanityCollection(link)) {
    return pages.collections;
  }
  if (isSanityProduct(link)) {
    return pages.products;
  }
  if (isSanityHomePage(link)) {
    return pages.home;
  }
  if (isSanityAboutPage(link)) {
    return pages.about;
  }
  if (isSanityCareersPage(link)) {
    return pages.careers;
  }

  if (isSanityFaqsPage(link)) {
    return pages.faqs;
  }
  if (isSanityPoliciesPage(link)) {
    return pages.policies;
  }
  if (isSanityPromotionalProducts(link)) {
    return pages.promotionalProducts;
  }
  if (isSanityResourcesPage(link)) {
    return pages.resources;
  }
  if (isSanityTestimonialsPage(link)) {
    return pages.testimonials;
  }
  return "/";
}

export function calculateGridItemBorders(index: number, lastItem: number, arrayLength: number) {
  const lastRowHasThree = (lastItem - 1) % 3 === 2 && index > arrayLength - 4;
  const lastRowHasTwo = (lastItem - 1) % 3 === 1 && index > arrayLength - 3;
  const lastRowHasOne = (lastItem - 1) % 3 === 0 && index === arrayLength - 1;
  const noBorderLeft = index === 0 || ((index + 1) % 3 === 1 && index + 1 > 3);

  return { lastRowHasOne, lastRowHasTwo, lastRowHasThree, noBorderLeft };
}

export const filterProductByRegion = (product: SanityProduct) => {
  return product.regions?.some(
    productRegion => productRegion?.region?.countryCode === process.env.GATSBY_REGION,
  );
};

export function filterProducts(products: SanityProduct[], filters?: Maybe<string[]> | undefined) {
  const filteredProducts = products.filter(product => {
    if (product == null) return;
    const categoriesList = product.categories?.map(category => category?.title);

    if (categoriesList && filters && filters.length > 0) {
      return (
        categoriesList.some(item => item && filters.includes(item)) &&
        filterProductByRegion(product)
      );
    }
    return filterProductByRegion(product);
  });
  return filteredProducts;
}

export function checkCategoryProductLength(
  categoryName: Maybe<string> | undefined,
  products: SanityProduct[] | undefined,
  collectionName?: Maybe<string> | undefined,
) {
  if (products && categoryName && collectionName) {
    const sortedProducts = products.filter(product => {
      return (
        product?.categories?.some(
          productCategory => productCategory && categoryName === productCategory.title,
        ) &&
        product?.categories?.some(
          productCategory =>
            productCategory && collectionName === productCategory?.collectionRef?.title,
        )
      );
    });
    return sortedProducts;
  }
  return;
}

export function flatten(arr: any) {
  return arr.reduce(function (flat: any, toFlatten: any) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}

export function getUniqueListBy(arr: any, key: string) {
  return [...new Map(arr.map((item: any) => [item[key], item])).values()];
}

export function sortProductsByCategoryOrder(
  collection: SanityCollection,
  products: SanityProduct[],
) {
  if (collection == null || products == null) return null;

  const categoriesArray = collection.collectionCategories?.map(category => {
    if (category == null) return;
    if (category.title == null) return;

    return filterProducts(products, [category?.title]);
  });

  const flattenedProducts = flatten(categoriesArray);
  const uniqueProducts = getUniqueListBy(flattenedProducts, "id");

  return uniqueProducts as SanityProduct[];
}

export function checkIsMicroSite() {
  if (process.env.GATSBY_IS_MICROSITE == null) return true;
  if (process.env.GATSBY_IS_MICROSITE === "false") return false;
  return true;
}

export function sortProductAttributes(attributes: SanityAttributeBlock[]) {
  let nonPackagingAttributes = attributes.filter(attribute => {
    if (attribute == null) return null;
    return attribute.attribute?.title !== "Packaging";
  });
  const packagingAttributes = attributes.filter(attribute => {
    if (attribute == null) return null;
    return attribute.attribute?.title === "Packaging";
  });

  packagingAttributes[0] && nonPackagingAttributes.push(packagingAttributes[0]);

  return nonPackagingAttributes;
}

export function checkCollectionProductLength(collection: SanityCollection) {
  if (collection == null) return null;
  const products = useAllProductQuery(collection.title);

  const regionProducts = products.filter(product => {
    if (product == null) return null;
    return filterProductByRegion(product);
  });

  return regionProducts.length;
}
