const client = require("@sanity/client");

import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

export const sanityClient = client({
  projectId: "v529ham6",
  dataset: process.env.SANITY_NODE_ENV ?? "production",
  apiVersion: "2021-03-25",
  useCdn: true,
});

export const imageBuilder = imageUrlBuilder(sanityClient).auto("format");

export function urlForImageRef(source?: SanityImageSource) {
  return imageBuilder.image(source ?? "");
}
