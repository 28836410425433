import React, { ReactNode, useEffect } from "react";
import ReactDrawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import styled from "styled-components";

import { colors, TABLET_BREAKPOINT } from "@util/constants";
import { Color } from "@util/types";
import { useCheckScreenWidth, usePrevious } from "@util/hooks";
import { Container } from "@util/standard";
import { IconButton } from "@global";

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 30px;
  right: 30px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: 30px;
    right: 10%;
    left: unset;
  }
`;
interface Props {
  width?: string;
  height?: string;
  children: ReactNode;
  placement: "right" | "left" | "top" | "bottom";
  duration?: number;
  visible: boolean;
  onClose: () => void;
  backgroundColor?: Color | string;
}

const Drawer = (props: Props) => {
  const prevVisible = usePrevious(props.visible);
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();
  const width = isTabletWidth || isMobileWidth ? "100%" : props.width ? props.width : "50%";

  useEffect(() => {
    if (prevVisible !== props.visible) {
      if (props.visible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "initial";
      }
    }
  }, [props.visible]);

  return (
    <ReactDrawer
      open={props.visible}
      onClose={props.onClose}
      direction={props.placement}
      overlayOpacity={0.6}
      style={{
        width: width,
        overflowY: "scroll",
        height: props.height ?? "100%",
        backgroundColor: props.backgroundColor
          ? `${colors[props.backgroundColor]}`
          : `${colors.white}`,
      }}
      duration={props.duration}
    >
      <Container height="100vh" width="100%" position="relative" margin="auto">
        <CloseButton onClick={props.onClose} icon="close" />

        <Container
          height="75%"
          width="69%"
          tabletWidth="100%"
          tabletHeight="100%"
          margin="auto"
          position="relative"
        >
          {props.children}
        </Container>
      </Container>
    </ReactDrawer>
  );
};

export default Drawer;
