import React, { useRef, useState } from "react";

import { BlocksContent, Image } from "@global";
import { SanityFeaturedCollections } from "@graphql-types";
import { Container } from "@util/standard";

import { formatInternalLink, isBrowser } from "@util/helper";
import { useCheckScreenWidth, useEventListener } from "@util/hooks";
import { ColumnWrapper, ParallaxWrapper, StyledLink, TextWrapper } from "./global.styles";

interface Props {
  data: SanityFeaturedCollections;
}

function ParallaxColumns({ data }: Props) {
  if (data == null || data.collectionRefs == null) return null;
  const [scrollValue, setScrollValue] = useState(0);
  const [globalScrollY, setGlobalScrollY] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const { isMobileWidth, isTabletWidth } = useCheckScreenWidth();

  const scrollColumns = () => {
    const isDesktop = !isMobileWidth && !isTabletWidth;

    if (isBrowser() && containerRef.current && isDesktop) {
      setGlobalScrollY(window.scrollY);

      const offsetTop = containerRef.current?.offsetTop;
      const height = containerRef.current.offsetHeight;
      const viewPortHalf = window.innerHeight / 2;
      if (
        globalScrollY > window.scrollY &&
        globalScrollY <= offsetTop + height + viewPortHalf &&
        globalScrollY <= offsetTop + height + viewPortHalf
      ) {
        setScrollValue(prev => (prev > 0 ? prev - 2 : prev));
      }
      if (
        globalScrollY < window.scrollY &&
        globalScrollY >= offsetTop + viewPortHalf &&
        globalScrollY <= offsetTop + height + viewPortHalf
      ) {
        setScrollValue(prev => (prev < 100 ? prev + 2 : prev));
      }
      return;
    }
    return;
  };

  useEventListener("scroll", scrollColumns);

  return (
    <ParallaxWrapper ref={containerRef}>
      {data.collectionRefs.map((collection, index) => {
        if (collection == null) return null;

        const defineColor =
          index % 3 === 0 ? "mint" : (index + 1) % 2 === 0 ? "cream" : "lightBlue";

        const isLast = index + 1 === data?.collectionRefs?.length;

        return (
          <ColumnWrapper
            index={index}
            backgroundColor={defineColor}
            isLast={isLast}
            scrollValue={scrollValue}
            key={collection._id}
          >
            <Container width="100%" height="130px">
              {collection.collectionIcon && <Image data={collection.collectionIcon} />}
            </Container>
            <TextWrapper>
              {collection.collectionDescription && (
                <BlocksContent data={collection.collectionDescription} />
              )}
            </TextWrapper>
            <StyledLink to={`${formatInternalLink(collection)}${collection?.slug?.current}`}>
              View {collection.title}
            </StyledLink>
          </ColumnWrapper>
        );
      })}
    </ParallaxWrapper>
  );
}

export default ParallaxColumns;
