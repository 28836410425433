import styled from "styled-components";
import { Link } from "gatsby";

import { Container } from "@util/standard";
import {
  MOBILE_BREAKPOINT,
  colors,
  TABLET_BREAKPOINT,
  colorsRGB,
  fontWeights,
} from "@util/constants";

// GLOBAL ITEMS

export const StandardLayout = styled(Container)<{
  margin?: string;
  tabletMargin?: string;
  width?: string;
  tabletWidth?: string;
  mobileWidth?: string;
}>`
  width: 70%;
  margin: auto;
  ${({ width }) => width && `width: ${width};`};
  ${({ margin }) => margin && `margin: ${margin};`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    flex-direction: column;
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`};
    ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
  }
`;

// END OF GLOBAL ITEMS

// THEME SELECTOR

export const CategoryContainer = styled(Container)<{ isFirst?: boolean; isLast?: boolean }>`
  flex-direction: column;
  width: 100%;
  margin: 85px auto 10px auto;
  cursor: pointer;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 35px auto 10px auto;
    ${({ isFirst }) => isFirst && `margin-left: 135px;`};
    ${({ isLast }) => isLast && `margin-right: 135px;`};
  }
`;

export const CategoryImageWrapper = styled(Container)<{
  hasImage?: boolean | undefined | null;
  selected?: boolean;
}>`
  height: 80px;
  width: 80px;
  margin: 0 auto;
  border-radius: 50%;

  ${({ hasImage }) => (hasImage ? "" : `background-color: ${colors.grey};`)};
  ${({ selected }) => (selected ? `opacity: 1;` : `opacity: 0.5;`)};
  .gatsby-image-wrapper {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    img {
      border-radius: 50%;

      -webkit-border-radius: 50%;
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 15px;
  }
`;

export const Icon = styled.img`
  width: 14px;
  height: 7px;
  margin: 0 auto 15px auto;
`;

export const ScrollContainer = styled(Container)`
  ::-webkit-scrollbar {
    display: none;
  }
`;
//  END OF THEME SELECTOR

// FILTER BAR

export const CategoryName = styled.p<{ selected?: boolean; isLast?: boolean }>`
  ${({ selected }) =>
    selected ? `font-weight: ${fontWeights.bold}` : `font-weight: ${fontWeights.normal}`};
  padding: 22px 0;
  border-bottom: 1px solid ${colorsRGB.navy(0.4)};
  ${({ isLast }) => isLast && `border-bottom: none;`};
  color: ${colors.navy};
  margin: 0;
  &:visited {
    color: ${colors.navy};
  }
  cursor: pointer;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 20px;
    border: none;
    padding: 0 0 20px 0;
  }
`;

// END OF FILTER BAR

// PARALLAX COLUMNS / FEATURED COLLECTIONS

export const TextWrapper = styled(Container)`
  h2,
  h3,
  h4 {
    font-size: 20px;
    margin-top: 63px;
  }
`;

export const ParallaxWrapper = styled(Container)`
  width: 96%;
  margin: 120px auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 30px auto 0 auto;
    overflow-x: scroll;
  }
`;

export const StyledLink = styled(Link)`
  color: ${colors.navy};
  text-decoration: underline;
  margin-top: 20px;
  white-space: normal;
`;

export const ColumnWrapper = styled(Container)<{
  index?: number;
  isLast?: boolean;
  scrollValue?: number;
}>`
  flex-direction: column;
  padding: 80px 2% 60px 2%;
  width: 24%;
  border-radius: 10px;
  height: max-content;
  ${({ index }) =>
    (index && index % 2 === 0) || index === 0 ? "margin: 100px 1% 0 0;" : "margin: 0 1% 0 0;"};
  ${({ isLast }) => isLast && `margin-right: 0px;`};
  justify-content: center;

  ${({ scrollValue, index }) =>
    (index && index % 2 === 0) || index === 0
      ? `margin: calc(100px - ${scrollValue}px) 1% 0 0;`
      : `margin: calc(0px + ${scrollValue}px) 1% 0 0;`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    min-width: 200px;
    ${({ index }) =>
      (index && index % 2 === 0) || index === 0 ? "margin: 50px 0 0 2%;" : "margin: 0 3%;"};
    padding: 80px 10% 60px 10%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 220px;
  }
`;

// END OF PARALLAX COLUMNS / FEATURED COLLECTIONS
