import { SelectedCalendar } from "@util/types";
import create, { SetState, GetState } from "zustand";
import { persist, devtools, StoreApiWithPersist } from "zustand/middleware";

export interface State {
  drawerVisible: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  disableAgeRestriction: () => void;
  openAgeRestrictionPopup: () => void;
  closeAgeRestrictionPopup: () => void;
  categoryFilters: string[];
  currentSearchValue: string;
  ageRestrictionActive: boolean;
  ageRestrictionPopupVisible: boolean;
  selectedCalendar: SelectedCalendar;
}

const defaultState = {
  categoryFilters: [],
  currentSearchValue: "",
  ageRestrictionActive: true,
  ageRestrictionPopupVisible: false,
};

const defaultSelectedCalendar = {
  productId: "",
  productName: "",
};

export const useStore = create(
  devtools(
    persist<State, SetState<State>, GetState<State>, StoreApiWithPersist<State>>(
      set => ({
        drawerVisible: false,
        openDrawer: () => set({ drawerVisible: true }),
        closeDrawer: () => set({ drawerVisible: false }),
        ageRestrictionActive: true,
        ageRestrictionPopupVisible: false,
        disableAgeRestriction: () => set({ ageRestrictionActive: false }),
        openAgeRestrictionPopup: () => set({ ageRestrictionPopupVisible: true }),
        closeAgeRestrictionPopup: () => set({ ageRestrictionPopupVisible: false }),
        categoryFilters: defaultState.categoryFilters,
        currentSearchValue: defaultState.currentSearchValue,
        selectedCalendar: defaultSelectedCalendar,
      }),
      {
        name: "easy2c-store", // unique name
        getStorage: () => localStorage,
      },
    ),
  ),
);
