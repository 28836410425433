import { Image } from "@global";
import { SanityProduct } from "@graphql-types";
import { AbsoluteContainer, Container, P } from "@util/standard";
import React, { useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Controller, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { assets, colors, MOBILE_BREAKPOINT, SMALL_LAPTOP_BREAKPOINT } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { ModalBox } from "@global";
import { useStore } from "@state/store";

interface Props {
  data: SanityProduct;
}

const StyledSwiper = styled(Swiper)`
  width: 100%;

  .swiper-pagination {
    display: none;
  }
`;

const ImageWrapper = styled(Container)<{ ageRestricted: boolean | null | undefined }>`
  .gatsby-image-wrapper {
    ${({ ageRestricted }) => ageRestricted && `filter: blur(10px);`};
  }
`;

const Thumbnail = styled(Container)`
  height: 65px;
  width: 65px;

  position: relative;
  cursor: pointer;

  .gatsby-image-wrapper {
    border-radius: 5px;
  }
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    height: 40px;
    width: 40px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 61px;
    height: 61px;
  }
`;

const Arrow = styled.img<{ right?: boolean }>`
  ${({ right }) => (right ? `margin-left: 25px;` : `margin-left: 35px;`)};

  cursor: pointer;
`;

const MixMatchContainer = styled(AbsoluteContainer)`
  top: 0px;
  left: 0px;
  background: ${colors.white};
  border-radius: 0 0 40px 0;
`;

const ModalArrow = styled.img<{ right?: boolean }>`
  position: absolute;
  left: -5%;
  ${({ right }) => right && `left: unset; right: -5%;`};
  top: 50%;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  filter: grayscale(1) brightness(500);
  cursor: pointer;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    left: -8%;
    ${({ right }) => right && `left: unset; right: -8%;`};
  }
`;

function ProductGallery({ data }: Props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [controller, setController] = useState<SwiperCore>();

  if (data == null || data.images == null) {
    return null;
  }
  const { ageRestrictionActive } = useStore();

  const { isMobileWidth } = useCheckScreenWidth();

  const handleClick = (next?: boolean) => {
    if (next) {
      controller?.slideNext();
      if (data.images && currentSlide + 1 < data.images.length) {
        setCurrentSlide(currentSlide + 1);
        return;
      }
      setCurrentSlide(0);
      return;
    }

    controller?.slidePrev();
    if (data.images && currentSlide - 1 > 0) {
      setCurrentSlide(currentSlide - 1);
      return;
    }
    setCurrentSlide(data.images ? data.images.length - 1 : 0);
    return;
  };

  return (
    <>
      <Container width="100%" tabletWidth="100%">
        <Container width="100%" flexDirection="column">
          <ImageWrapper
            ageRestricted={data.adultContent && ageRestrictionActive}
            height="60vh"
            tabletHeight="60vh"
            margin="0 0 20px 0"
            width="100%"
            position="relative"
            onClick={() => (data.images && data.images.length > 0 ? setModalVisible(true) : null)}
            cursor="pointer"
          >
            {data.images && data.images[currentSlide] ? (
              <Image objectFit="contain" data={data.images[currentSlide]} isBackground />
            ) : (
              <img
                style={{ width: "100%", objectFit: "contain" }}
                src={assets.productDefaultImage}
              />
            )}
            <MixMatchContainer>
              {data.mixMatch && (
                <P color="blue" fontWeight="bold" padding="20px 35px 20px 25px" margin="0">
                  {"Mix & Match"}
                </P>
              )}
              {data.flexiProduct && (
                <P color="blue" fontWeight="bold" padding="20px 35px 20px 25px" margin="0">
                  Flexi Product
                </P>
              )}
              {data.fixedProduct && (
                <P color="blue" fontWeight="bold" padding="20px 35px 20px 25px" margin="0">
                  Fixed Product
                </P>
              )}
            </MixMatchContainer>
          </ImageWrapper>
          {data.images && (
            <StyledSwiper
              modules={[Controller, Pagination, Navigation]}
              onSwiper={setController}
              slidesPerView={isMobileWidth ? 5 : 8}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
            >
              {data.images.map((image, index) => {
                if (image == null) {
                  return null;
                }

                return (
                  <SwiperSlide onClick={() => setCurrentSlide(index)} key={image._key}>
                    <Thumbnail>
                      <Image data={image} isBackground />
                    </Thumbnail>
                  </SwiperSlide>
                );
              })}
            </StyledSwiper>
          )}
          {data.images.length > 8 && (
            <Container>
              <P fontWeight="bold">
                {currentSlide + 1} / {data.images.length}
              </P>
              <Arrow src={assets.arrowLeft} onClick={() => handleClick()} />
              <Arrow right src={assets.arrowRight} onClick={() => handleClick(true)} />
            </Container>
          )}
        </Container>
      </Container>

      <ModalBox visible={modalVisible} setVisible={setModalVisible}>
        {data.images && data.images.length > 1 && (
          <>
            <ModalArrow src={assets.arrowLeft} onClick={() => handleClick()} />
            <ModalArrow src={assets.arrowRight} right onClick={() => handleClick(true)} />
          </>
        )}
        <Image objectFit="contain" data={data.images[currentSlide]} isBackground />
      </ModalBox>
    </>
  );
}

export default ProductGallery;
