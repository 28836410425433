import React, { useEffect } from "react";
import styled from "styled-components";

import { SanityAttributeBlock, SanityProduct, SanityProductConnection } from "@graphql-types";
import { Container, P } from "@util/standard";
import ProductGallery from "./productGallery";
import { BlocksContent, Button, AttributesBlock } from "@global";
import { colorsRGB } from "@util/constants";
import { useStore } from "@state/store";
import MixMatchPromotional from "./mixMatchPromotional";
import ProductNavArrows from "./productNavArrows";

interface Props {
  data: SanityProduct;
  navButtonProducts: SanityProductConnection;
}

const Title = styled.h1`
  margin: 20px 0;
`;

const StyledContainer = styled.div`
  border-bottom: 1px solid ${colorsRGB.navy(0.6)};
  padding-bottom: 40px;
`;

function ProductHero({ data, navButtonProducts }: Props) {
  if (data == null) {
    return null;
  }

  const { openDrawer, selectedCalendar } = useStore();

  //Set the product in store
  useEffect(() => {
    if (selectedCalendar.productId != data.productId) {
      const product = {
        productName: data.title,
        productId: data.productId,
      };
      useStore.setState({ selectedCalendar: product });
    }
  }),
    [];

  return (
    <Container
      width="80%"
      tabletWidth="92%"
      tabletFlexDirection="column"
      margin="0 auto 140px auto"
      padding="215px 0 0 0"
      mobilePadding="127px 0 0 0"
      tabletMargin="0 auto 45px auto"
      maxWidth="1220px"
    >
      <ProductNavArrows
        slug={data.slug}
        collection={data.categories && data.categories[0] && data.categories[0].collectionRef}
        productData={navButtonProducts}
      />
      <Container margin="0 60px 0 0" width="51%" tabletWidth="100%">
        <ProductGallery data={data} />
      </Container>
      <Container
        flexDirection="column"
        width="43%"
        tabletWidth="90%"
        tabletMargin="62px auto 0 auto"
      >
        {data?.categories?.length && data?.categories[0]?.collectionRef && (
          <P margin="0">{data.categories[0]?.collectionRef?.title}</P>
        )}
        <Title>{data.title}</Title>
        <StyledContainer>
          <BlocksContent data={data.productDescription} />
          {data.advertSize && data.productSize && (
            <Container flexDirection="column" margin="30px 0 0 0">
              {data.productSize && data.productSize.width && data.productSize.height && (
                <P margin="0 0 5px 0">
                  Calendar size - {data.productSize.width}mm X {data.productSize.height}mm
                </P>
              )}
              {data.advertSize.width && data.advertSize.height && (
                <P margin="0">
                  Advert size - {data.advertSize.width}mm X {data.advertSize.height}mm
                </P>
              )}
            </Container>
          )}
        </StyledContainer>
        <StyledContainer>
          {data.attributes && <AttributesBlock data={data.attributes as SanityAttributeBlock[]} />}
          <Button margin="30px 0 0px 0" linkText="Enquire about product" onClick={openDrawer} />
        </StyledContainer>
        <MixMatchPromotional
          fixedProduct={data.fixedProduct}
          flexiProduct={data.flexiProduct}
          mixMatch={data.mixMatch}
          product={data}
        />
      </Container>
    </Container>
  );
}

export default ProductHero;
