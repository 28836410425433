import styled, { createGlobalStyle } from "styled-components";

import { colors, fontFamilies, MOBILE_BREAKPOINT } from "@util/constants";
import { fontSizes, fontWeights, PRIMARY_COLOR } from "@util/constants";

export const GlobalStyle = createGlobalStyle`
  
  body {
    font-family: ${fontFamilies.gotham};
    margin: 0px;
    font-size: ${fontSizes.p.default}px;
    font-weight: ${fontWeights.normal};
    color: ${colors.navy};
    white-space: pre-wrap;
    overflow-x: hidden;
  }

  h1 {
    font-size: ${fontSizes.h1.default}px;
    font-weight: ${fontWeights.bold};
    white-space: pre-wrap;

  }

  h2 {
    font-size: ${fontSizes.h2.default}px;
    font-weight: ${fontWeights.bold};
    white-space: pre-wrap;
  }
 
  h3 {
    font-size: ${fontSizes.h3.default}px;
    font-weight: ${fontWeights.bold};
    white-space: pre-wrap;
  }

  h4 {
    font-size: ${fontSizes.h4.default}px;
    font-weight: ${fontWeights.medium};
    white-space: pre-wrap;
  }

  a {
    user-select: none;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: ${fontWeights.medium};
    color:${colors.navy};

    &:hover {
      filter: brightness(0.9);
    }
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-kerning: normal;
  font-kerning: normal;
  input,
  textarea,
  button,
  select,
  a, img, div {
      -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
  body {
    font-size: ${fontSizes.p.mobile}px;
  }

  h1 {
    font-size: ${fontSizes.h1.mobile}px;
  }

  h2 {
    font-size: ${fontSizes.h2.mobile}px;
  }
 
  h3 {
    font-size: ${fontSizes.h3.mobile}px;
  }

  h4 {
    font-size: ${fontSizes.h4.mobile}px;
  }
  }

`;

export const Main = styled.main<{}>``;
