export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1279;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;

export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  navy: "#003352",
  lightBlue: "#E0F0FF",
  blue: "#00639E",
  cream: "#F9F5F0",
  mint: "#DFF8EB",
  green: "#06765E",
  grey: "#E1E1E1",
  teal: "#01C395",
  skyBlue: "#2EA3D4",
};

export const fontFamilies = {
  gotham: "gotham",
};

export const fontWeights = {
  normal: 400,
  medium: 500,
  bold: 700,
};

export const PRIMARY_COLOR = colors.skyBlue;
export const SECONDARY_COLOR = colors.black;

export const fontSizes = {
  p: { default: 14, mobile: 14 },
  h1: { default: 50, mobile: 36 },
  h2: { default: 30, mobile: 25 },
  h3: { default: 20, mobile: 20 },
  h4: { default: 20, mobile: 20 },
  span: { default: 16, mobile: 12 },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  navy: (opacity?: number) => `rgba(0, 51, 82, ${opacity ?? "1"})`,
  lightBlue: (opacity?: number) => `rgba(224, 240, 255, ${opacity ?? "1"})`,
};

export const filters = {
  blue: `invert(13%) sepia(94%) saturate(6635%) hue-rotate(192deg) brightness(94%) contrast(102%);`,
};

// define paths to asset
export const assets = {
  logo: require("@static/assets/logo.png").default,
  exampleCalendar: require("@static/assets/example.jpeg").default,
  arrowLeft: require("@static/assets/arrowLeft.svg").default,
  arrowRight: require("@static/assets/arrowRight.svg").default,
  arrowDown: require("@static/assets/arrowDown.svg").default,
  close: require("@static/assets/close.svg").default,
  closeWhite: require("@static/assets/closeWhite.svg").default,
  cursor: require("@static/assets/cursor.svg").default,
  checked: require("@static/assets/checked.svg").default,
  unChecked: require("@static/assets/unChecked.svg").default,
  filterIcon: require("@static/assets/filterIcon.svg").default,
  promotionalProduct: require("@static/assets/promotionalProduct.svg").default,
  mixAndMatch: require("@static/assets/mixAndMatch.svg").default,
  attributeDefaultIcon: require("@static/assets/attributeDefaultIcon.svg").default,
  search: require("@static/assets/search.svg").default,
  hamburger: require("@static/assets/hamburger.svg").default,
  playButton: require("@static/assets/playButton.svg").default,
  productDefaultImage: require("@static/assets/productDefaultImage.png").default,
  loadingIcon: require("@static/assets/loadingIcon.svg").default,
};

export const buttonStyle = {
  base: {
    bg: `${colors.navy}`,
    text: `${colors.white}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.navy}`,
    hoverText: `${colors.navy}`,
  },
  baseWhite: {
    bg: `${colors.white}`,
    text: `${colors.navy}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.navy}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colors.white}`,
  },
  baseGreen: {
    bg: `${colors.green}`,
    text: `${colors.white}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colors.green}`,
  },
  baseBlue: {
    bg: `${colors.blue}`,
    text: `${colors.white}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colors.blue}`,
  },
  bordered: {
    bg: `${colors.transparent}`,
    text: `${colors.navy}`,
    border: `${colors.navy}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.navy}`,
    hoverText: `${colors.navy}`,
  },
  borderedWhite: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.white}`,
    hoverText: `${colors.white}`,
  },
  borderedBlue: {
    bg: `${colors.transparent}`,
    text: `${colors.blue}`,
    border: `${colors.blue}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.blue}`,
    hoverText: `${colors.blue}`,
  },
  borderedGreen: {
    bg: `${colors.transparent}`,
    text: `${colors.green}`,
    border: `${colors.green}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.green}`,
    hoverText: `${colors.green}`,
  },
  text: {
    bg: `${colors.transparent}`,
    text: `${colors.navy}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colors.navy}`,
    underlined: true,
  },
  textWhite: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colors.white}`,
    underlined: true,
  },
  textBlue: {
    bg: `${colors.transparent}`,
    text: `${colors.blue}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colors.blue}`,
    underlined: true,
  },
  textGreen: {
    bg: `${colors.transparent}`,
    text: `${colors.green}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colors.green}`,
    underlined: true,
  },
};

export const animationTypes = {
  fadeIn: {
    to: { opacity: 1 },
    from: { opacity: 0 },
  },
  fadeOut: {
    to: { opacity: 0 },
    from: { opacity: 1 },
  },
  fadeInFromLeft: {
    to: { transform: "translateX(0px)", opacity: 1 },
    from: { transform: "translateX(-200px)", opacity: 0 },
  },
  fadeInFromRight: {
    to: { transform: "translateX(0px)", opacity: 1 },
    from: { transform: "translateX(200px)", opacity: 0 },
  },
  fadeInFromTop: {
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(-200px)", opacity: 0 },
  },
  fadeInFromBottom: {
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(200px)", opacity: 0 },
  },
  rotateLeft: {
    to: { transform: "rotateZ(0deg)" },
    from: { transform: "rotateZ(360deg)" },
  },
  rotateRight: {
    to: { transform: "rotateZ(360deg)" },
    from: { transform: "rotateZ(0deg)" },
  },
  slideInFromRight: (distance: string, opacity?: number) => {
    return {
      to: { transform: "translateX(0px)", opacity: 1 },
      from: { transform: `translateX(${distance})`, opacity: opacity !== 1 ? opacity : 1 },
    };
  },
  slideInFromTop: (distance: string, opacity?: number) => {
    return {
      to: { transform: "translateY(0px)", opacity: 1 },
      from: { transform: `translateY(${distance})`, opacity: opacity !== 1 ? opacity : 1 },
    };
  },
  noAnimation: {
    to: { opacity: 1 },
    from: { opacity: 1 },
  },
};
